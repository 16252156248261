var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      ref: "modal-2",
      staticClass: "modal-sm",
      attrs: { active: _vm.modal, center: true },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "card-body p-4",
          on: {
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submit.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column col-12" },
              [
                _c("span", { staticClass: "form-label mx-auto" }, [
                  _vm._v(_vm._s(_vm.$t("active_category")))
                ]),
                _vm._v(" "),
                _c(
                  "mega-drop-zone",
                  {
                    staticClass: "rounded w-50 mx-auto",
                    staticStyle: { width: "10rem" },
                    attrs: { type: ["image/png"] },
                    on: { change: _vm.renderActive }
                  },
                  [
                    _c("mega-image", {
                      staticClass: "image bg-contain rounded",
                      style: { backgroundSize: "contain" },
                      attrs: { ratio: "1x1", src: _vm.activePreview }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-100 col-12 text-center mt-2" },
              [
                _c("mega-switch", {
                  staticClass: "w-100 mb-0 bg-success",
                  attrs: { label: _vm.$t("category_show_icon"), onStart: true },
                  model: {
                    value: _vm.showIcon,
                    callback: function($$v) {
                      _vm.showIcon = $$v
                    },
                    expression: "showIcon"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form d-flex flex-column mt-4" },
            [
              _c("span", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("category_name_loc")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row mb-0 mt-3" },
                [
                  _c("span", { staticClass: "col-1 text-center" }, [
                    _vm._v(" RU ")
                  ]),
                  _vm._v(" "),
                  _c("mega-input", {
                    staticClass: "col-10 ml-auto",
                    attrs: { placeholder: _vm.$t("category_name") },
                    model: {
                      value: _vm.label_ru,
                      callback: function($$v) {
                        _vm.label_ru = $$v
                      },
                      expression: "label_ru"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row mb-0 mt-3" },
                [
                  _c("span", { staticClass: "col-1 text-center" }, [
                    _vm._v(" EN ")
                  ]),
                  _vm._v(" "),
                  _c("mega-input", {
                    staticClass: "col-10 ml-auto",
                    attrs: { placeholder: _vm.$t("category_name") },
                    model: {
                      value: _vm.label_en,
                      callback: function($$v) {
                        _vm.label_en = $$v
                      },
                      expression: "label_en"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.localizations, function(loc, id) {
                return _c(
                  "div",
                  { key: id, staticClass: "row mb-0 mt-3" },
                  [
                    _c("mega-input", { staticClass: "col-1" }),
                    _vm._v(" "),
                    _c("mega-input", {
                      staticClass: "col-10 ml-auto",
                      attrs: { placeholder: _vm.$t("category_name") }
                    })
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("mega-switch", {
                staticClass: "w-100 mb-0 bg-success",
                attrs: { label: _vm.$t("category_show_text"), onStart: true },
                model: {
                  value: _vm.showText,
                  callback: function($$v) {
                    _vm.showText = $$v
                  },
                  expression: "showText"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "navbar sm-down:flex-column-reverse md-up:justify-content-end"
                },
                [
                  _c(
                    "mega-button",
                    {
                      staticClass: "bg-white sm-down:w-100 md-up:mr-2",
                      on: {
                        click: function($event) {
                          _vm.modal = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("close")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "mega-button",
                    {
                      staticClass: "btn-primary sm-down:w-100 sm-down:mb-3",
                      attrs: { disabled: _vm.isDisabled },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(_vm._s(_vm.$t("edit_category")))]
                  )
                ],
                1
              )
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }