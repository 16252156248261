var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-card",
    { staticClass: "card-border card-round rounded" },
    [
      _c("div", { staticClass: "navbar py-3 stream-container p-relative" }, [
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _c(
              "router-link",
              {
                staticClass: "navbar-label w-100 text-dark",
                attrs: {
                  to: {
                    name: _vm.type,
                    query: {
                      ruCategory: _vm.data.label_ru,
                      enCategory: _vm.data.label_en,
                      id: _vm.id
                    }
                  }
                }
              },
              [
                _c("mega-image", {
                  staticClass:
                    "navbar-avatar mr-3 d-flex text-center justify-content-center align-items-center",
                  attrs: { ratio: "1x1", src: _vm.icon }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "p-absolute small",
                    staticStyle: { bottom: "-15px", left: "0px" }
                  },
                  [
                    _vm.data.hide_icon == false
                      ? _c("i", { staticClass: "i-eye" })
                      : _c("i", { staticClass: "i-eye-off" })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "content p-relative d-flex text-center justify-content-center align-items-center",
                    staticStyle: { width: "calc(100% - 78px)" }
                  },
                  [
                    _c("small", { staticClass: "strong text-overflow" }, [
                      _c("span", [_vm._v(_vm._s(_vm.label_ru || _vm.label_en))])
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "p-absolute cursor-pointer",
            staticStyle: { top: "0px", right: "4px" },
            on: {
              click: function($event) {
                return _vm.$refs.editCategory.open()
              }
            }
          },
          [_c("i", { staticClass: "i-edit-3" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "p-absolute cursor-pointer",
            staticStyle: { bottom: "0px", right: "4px" },
            on: {
              click: function($event) {
                return _vm.$refs.deleteCategory.open()
              }
            }
          },
          [_c("i", { staticClass: "i-trash" })]
        )
      ]),
      _vm._v(" "),
      _c(
        "delete-confirm",
        {
          ref: "deleteCategory",
          attrs: {
            "data-click": "ignore",
            title: _vm.$t("category_del_title"),
            text: _vm.$t("category_del_confirm"),
            rounds: _vm.data.label_ru ? _vm.data.label_ru : _vm.data.label_en
          },
          on: { confirm: _vm.deleteCategory }
        },
        [
          _c("span", { staticClass: "badge m-2" }, [
            _vm._v(_vm._s(_vm.data.label_ru))
          ])
        ]
      ),
      _vm._v(" "),
      _c("edit-category", {
        ref: "editCategory",
        attrs: { "data-click": "ignore", data: _vm.data },
        on: { changePreview: _vm.changePreview }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }