<template>
  <mega-card class="card-border card-round rounded">
    <div class="navbar py-3 stream-container p-relative">
      <div class="container-fluid">
        <router-link
          :to="{
            name: type,
            query: {
              ruCategory: data.label_ru,
              enCategory: data.label_en,
              id: id
            }
          }"
          class="navbar-label w-100 text-dark"
        >
          <mega-image
            class="navbar-avatar mr-3 d-flex text-center justify-content-center align-items-center"
            ratio="1x1"
            :src="icon"
          />

          <div class="p-absolute small" style="bottom: -15px; left: 0px">
            <i class="i-eye" v-if="data.hide_icon == false"></i>
            <i class="i-eye-off" v-else></i>
          </div>

          <div
            class="content p-relative d-flex text-center justify-content-center align-items-center"
            style="width: calc(100% - 78px);"
          >
            <small class="strong text-overflow">
              <span>{{ label_ru || label_en }}</span>
            </small>
          </div>
        </router-link>
      </div>
      <div
        class="p-absolute cursor-pointer"
        style="top: 0px; right: 4px"
        @click="$refs.editCategory.open()"
      >
        <i class="i-edit-3"></i>
      </div>
      <div
        class="p-absolute cursor-pointer"
        style="bottom: 0px; right: 4px"
        @click="$refs.deleteCategory.open()"
      >
        <i class="i-trash"></i>
      </div>
    </div>
    <delete-confirm
      ref="deleteCategory"
      data-click="ignore"
      :title="$t('category_del_title')"
      :text="$t('category_del_confirm')"
      @confirm="deleteCategory"
      :rounds="data.label_ru ? data.label_ru : data.label_en"
    >
      <span class="badge m-2">{{ data.label_ru }}</span>
    </delete-confirm>
    <edit-category
      data-click="ignore"
      :data="data"
      ref="editCategory"
      @changePreview="changePreview"
    />
  </mega-card>
</template>

<script>
import deleteConfirm from "../../../components/delete-confirm";
import editCategory from "./edit-category";
export default {
  name: "CategoryCard",
  components: { deleteConfirm, editCategory },
  props: ["data"],
  computed: {
    icon() {
      return this.data.icon;
    },
    label_ru() {
      return this.data.label_ru;
    },
    label_en() {
      return this.data.label_en;
    },
    id() {
      return this.data.id;
    },
    type() {
      return (
        this.$route.params.category_type.charAt(0).toUpperCase() +
        this.$route.params.category_type.slice(1)
      );
    }
  },
  methods: {
    changePreview(active, disabled) {
      active
        ? (this.data.active_icon = active)
        : console.log("nothing to change");
      disabled ? (this.data.disabled_icon = disabled) : "";
    },
    deleteCategory() {
      switch (this.$route.params.category_type) {
        case "streams":
          this.$api.v2base
            .delete("/streams/admin/categories", { data: { id: this.data.id } })
            .then(() => {
              this.$refs.deleteCategory.close();
              this.$alert.success(this.$t("success"));
              this.$emit("category-deleted", this.data.id);
            })
            .catch(err => {
              console.log(err);
              this.$alert.danger(this.$t("round_likes_success"));
            });
          break;
        case "channels":
          this.$api.msg
            .delete("/admin/category/category", {
              params: { category_id: this.data.id }
            })
            .then(() => {
              this.$refs.deleteCategory.close();
              this.$alert.success(this.$t("success"));
              this.$emit("category-deleted", this.data.id);
            })
            .catch(err => {
              console.log(err);
              this.$alert.danger(this.$t("round_likes_success"));
            });
          break;
      }
    }
  }
};
</script>

<style></style>
