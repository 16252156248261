<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="modal = false"
  >
    <div class="card-body p-4" @keypress.enter="submit">
      <div class="row">
        <div class="d-flex flex-column col-12">
          <span class="form-label mx-auto">{{ $t("active_category") }}</span>
          <mega-drop-zone
            @change="renderActive"
            class="rounded w-50 mx-auto"
            :type="['image/png']"
            style="width: 10rem"
          >
            <mega-image
              class="image bg-contain rounded"
              ratio="1x1"
              :style="{ backgroundSize: 'contain' }"
              :src="activePreview"
            />
          </mega-drop-zone>
        </div>
        <div class="w-100 col-12 text-center mt-2">
          <mega-switch
            class="w-100 mb-0 bg-success"
            :label="$t('category_show_icon')"
            v-model="showIcon"
            :onStart="true"
          />
        </div>
      </div>
      <div class="form d-flex flex-column mt-4">
        <span class="form-label">{{ $t("category_name_loc") }}</span>
        <div class="row mb-0 mt-3">
          <span class="col-1 text-center"> RU </span>
          <mega-input
            v-model="label_ru"
            class="col-10 ml-auto"
            :placeholder="$t('category_name')"
          />
        </div>
        <div class="row mb-0 mt-3">
          <span class="col-1 text-center"> EN </span>
          <mega-input
            v-model="label_en"
            class="col-10 ml-auto"
            :placeholder="$t('category_name')"
          />
        </div>
        <div class="row mb-0 mt-3" v-for="(loc, id) in localizations" :key="id">
          <mega-input class="col-1" />
          <mega-input
            class="col-10 ml-auto"
            :placeholder="$t('category_name')"
          />
        </div>
        <mega-switch
          class="w-100 mb-0 bg-success"
          :label="$t('category_show_text')"
          v-model="showText"
          :onStart="true"
        />
        <!-- <i
          class="i-plus mx-auto cursor-pointer btn"
          @click="localizations.push('')"
        >
        </i> -->

        <div
          class="navbar sm-down:flex-column-reverse md-up:justify-content-end"
        >
          <mega-button
            class="bg-white sm-down:w-100 md-up:mr-2"
            @click="modal = false"
            >{{ $t("close") }}</mega-button
          >
          <mega-button
            :disabled="isDisabled"
            class="btn-primary sm-down:w-100 sm-down:mb-3"
            @click="submit"
            >{{ $t("edit_category") }}</mega-button
          >
        </div>
      </div>
    </div>
  </mega-modal>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      modal: false,
      disabledPreview: this.data.disabled_icon,
      activePreview: this.data.icon,
      localizations: [],
      label_ru: this.data.label_ru,
      label_en: this.data.label_en,
      showIcon: !this.data.hide_icon,
      showText: !this.data.hide_label,
      activeFile: ""
    };
  },
  computed: {
    isDisabled() {
      return !(
        this.label_ru &&
        this.label_en &&
        (this.activeFile || this.activePreview) &&
        (this.showText || this.showIcon)
      );
    }
  },
  methods: {
    renderDisabled(file) {
      if (!file) {
        this.$alert.danger(this.$t("err_ref_file"));
        return;
      }

      this.validateImageSize(file)
        .then(() => {
          this.disabledFile = file;

          let reader = new FileReader();
          reader.readAsDataURL(this.disabledFile);
          reader.onloadend = () => {
            this.disabledPreview = reader.result;
          };
        })
        .catch(err => {
          err == "less"
            ? this.$alert.danger(this.$t("err_ref_size"))
            : this.$alert.danger(this.$t("err_ref_size_more"));
          return;
        });
    },

    renderActive(file) {
      if (!file) {
        this.$alert.danger(this.$t("err_ref_file"));
        return;
      }

      this.validateImageSize(file)
        .then(() => {
          this.activeFile = file;

          let reader = new FileReader();
          reader.readAsDataURL(this.activeFile);
          reader.onloadend = () => {
            this.activePreview = reader.result;
          };
        })
        .catch(err => {
          err == "less"
            ? this.$alert.danger(this.$t("err_ref_size"))
            : this.$alert.danger(this.$t("err_ref_size_more"));
          return;
        });
    },

    open() {
      this.modal = true;
      console.log(this.data);
    },

    close() {
      this.modal = false;
    },

    validateImageSize(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.onload = () => {
            switch (this.$route.params.category_type) {
              case "streams":
                if (img.width < 100 && img.height < 100) {
                  reject("less");
                } else if (img.width > 500 && img.height > 500) {
                  reject("more");
                }

                resolve();
                break;
              case "channels":
                if (img.width < 10 && img.height < 10) {
                  reject("less");
                } else if (img.width > 200 && img.height > 200) {
                  reject("more");
                }

                resolve();
                break;
            }
          };

          img.src = reader.result;
        };

        reader.readAsDataURL(file);
      });
    },

    async submit() {
      let formData = new FormData();
      // let locale = JSON.stringify(this.localizationResult);
      // if (this.activeFile) {
      //   this.validateImageSize(this.activeFile).catch(err => {
      //     err == "less"
      //       ? this.$alert.danger(this.$t("err_ref_size"))
      //       : this.$alert.danger(this.$t("err_ref_size_more"));
      //   });
      // }

      // if (this.disabledFile) {
      //   this.validateImageSize(this.disabledFile).catch(err => {
      //     err == "less"
      //       ? this.$alert.danger(this.$t("err_ref_size"))
      //       : this.$alert.danger(this.$t("err_ref_size_more"));
      //   });
      // }

      switch (this.$route.params.category_type) {
        case "streams":
          this.activeFile && formData.append("icon", this.activeFile);
          formData.append("label_ru", this.label_ru);
          formData.append("label_en", this.label_en);
          formData.append("hide_icon", !this.showIcon);
          formData.append("hide_label", !this.showText);
          formData.append("id", this.data.id);

          this.$api.v2base
            .put("/streams/admin/categories", formData, {
              headers: { "Content-Type": "multipart/form-data" }
            })
            .then(() => {
              this.close();
              this.$alert.success(this.$t("round_likes_success"));
            })
            .catch(() => {
              this.$alert.danger(this.$t("err_server"));
            });

          this.$emit("changePreview", this.activePreview, this.disabledPreview);
          break;
        case "channels":
          if (this.activeFile) {
            formData.append("file", this.activeFile);

            this.$api.msg
              .post("/upload", formData, {
                headers: { "Content-Type": "multipart/form-data" }
              })
              .then(res => {
                const req_obj = {
                  category_id: this.data.id,
                  label_ru: this.label_ru,
                  label_en: this.label_en,
                  hide_label: !this.showText,
                  hide_icon: !this.showIcon,
                  icon: res.data.id
                };
                formData.delete("file");
                this.$api.msg
                  .put("/admin/category/category", req_obj, {
                    headers: { "Content-Type": "application/json" }
                  })
                  .then(res => {
                    this.$emit("category-created", res.data);
                    this.close();
                    this.$alert.success(this.$t("round_likes_success"));
                  })
                  .catch(() => {
                    this.$alert.danger(this.$t("err_server"));
                  });
              });
          } else {
            const req_obj = {
              category_id: this.data.id,
              label_ru: this.label_ru,
              label_en: this.label_en,
              hide_label: !this.showText,
              hide_icon: !this.showIcon
            };
            this.$api.msg
              .put("/admin/category/category", req_obj, {
                headers: { "Content-Type": "application/json" }
              })
              .then(res => {
                this.$emit("category-created", res.data);
                this.close();
                this.$alert.success(this.$t("round_likes_success"));
              })
              .catch(() => {
                this.$alert.danger(this.$t("err_server"));
              });
          }
          break;
      }
    }
  }
};
</script>
