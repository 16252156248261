<template>
  <div class="main">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <span
            @click="$refs['new-category'].open()"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill ml-auto"
          >
            <i class="i-globe float-left"></i>
            <span>{{ $t("new_category") }}</span>
          </span>
        </nav>
      </div>
    </section>

    <section>
      <draggable
        v-model="categories"
        :animation="200"
        class="container py-3 row"
        @update="changeCategoriesOrder($event)"
      >
        <div
          class="card-wrapper sm:col-6 md-up:col-3"
          v-for="category in categories"
          :key="category.id"
        >
          <category-card :data="category" @category-deleted="removeCategory" />
        </div>
      </draggable>
    </section>

    <new-category ref="new-category" @category-created="createCategory" />
  </div>
</template>

<script>
import NewCategory from "./components/new-category";
import CategoryCard from "./components/category-card";
import draggable from "vuedraggable";

export default {
  name: "Categories",
  components: {
    CategoryCard,
    NewCategory,
    draggable
  },
  data() {
    return {
      categories: []
    };
  },
  mounted() {
    console.log(this.$route);
    this.$navbar.name = "Категории";
    this.getCategoryList();
  },
  methods: {
    changeCategoriesOrder(e) {
      console.log(this.categories[e.newIndex]);
      if (this.$route.params.category_type == "streams") {
        let body = {
          target: this.categories[e.newIndex].id,
          after: this.categories[e.newIndex - 1]
            ? this.categories[e.newIndex - 1].id
            : -1
        };
        this.$api.v2base
          .put("/streams/admin/categories/ordering", body)
          .then(() => {
            this.$alert.success(this.$t("success"));
          })
          .catch(err => {
            console.log(err);
            this.$alert.danget(this.$t("err_server"));
          });
      } else if (this.$route.params.category_type == "channels") {
        let body = {
          category_id: this.categories[e.newIndex].id,
          after_category_id: this.categories[e.newIndex - 1]
            ? this.categories[e.newIndex - 1].id
            : 0
        };
        this.$api.msg
          .put("/admin/category/move", body)
          .then(() => {
            this.$alert.success(this.$t("success"));
          })
          .catch(err => {
            console.log(err);
            this.$alert.danget(this.$t("err_server"));
          });
      }
    },

    getCategoryList() {
      switch (this.$route.params.category_type) {
        case "streams":
          this.$api.v2base.get("/streams/categories").then(res => {
            this.categories = res.data;
          });
          break;
        case "channels":
          this.$api.msg.get("admin/category/list").then(res => {
            this.categories = res.data;
          });
          break;
      }
    },
    removeCategory(id) {
      this.categories = this.categories.filter(category => {
        return category["id"] !== id;
      });
    },

    createCategory(category) {
      console.log("AAAAA", category);
      this.categories.push(category);
    }
  }
};
</script>

<style scoped>
.card-wrapper {
  transform: none !important;
}
</style>
