var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c("nav", { staticClass: "nav sm-down:flex-column" }, [
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-fill ml-auto",
                  on: {
                    click: function($event) {
                      return _vm.$refs["new-category"].open()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "i-globe float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("new_category")))])
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        [
          _c(
            "draggable",
            {
              staticClass: "container py-3 row",
              attrs: { animation: 200 },
              on: {
                update: function($event) {
                  return _vm.changeCategoriesOrder($event)
                }
              },
              model: {
                value: _vm.categories,
                callback: function($$v) {
                  _vm.categories = $$v
                },
                expression: "categories"
              }
            },
            _vm._l(_vm.categories, function(category) {
              return _c(
                "div",
                {
                  key: category.id,
                  staticClass: "card-wrapper sm:col-6 md-up:col-3"
                },
                [
                  _c("category-card", {
                    attrs: { data: category },
                    on: { "category-deleted": _vm.removeCategory }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("new-category", {
        ref: "new-category",
        on: { "category-created": _vm.createCategory }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }