<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="modal = false"
  >
    <div class="card-body p-4" @keypress.enter="submit">
      <div class="row">
        <div class="d-flex flex-column col-12">
          <span class="form-label mx-auto">{{ $t("active_category") }}</span>
          <mega-drop-zone
            @change="renderActive"
            class="rounded w-50 mx-auto"
            :type="['image/png']"
            style="width: 10rem"
          >
            <mega-image
              class="image bg-contain rounded"
              ratio="1x1"
              :style="{ backgroundSize: 'contain' }"
              :src="activePreview"
            />
          </mega-drop-zone>
        </div>
        <div class="w-100 col-12 text-center mt-2">
          <mega-switch
            class="w-100 mb-0 bg-success"
            :label="$t('category_show_icon')"
            v-model="showIcon"
            :onStart="true"
          />
        </div>
      </div>
      <div class="form d-flex flex-column mt-4">
        <span class="form-label">{{ $t("category_name_loc") }}</span>
        <div class="row mb-0 mt-3">
          <span class="col-1 text-center"> RU </span>
          <mega-input
            v-model="label_ru"
            class="col-10 ml-auto"
            :placeholder="$t('category_name')"
          />
        </div>
        <div class="row mb-0 mt-3">
          <span class="col-1 text-center"> EN </span>
          <mega-input
            v-model="label_en"
            class="col-10 ml-auto"
            :placeholder="$t('category_name')"
          />
        </div>
        <mega-switch
          class="w-100 mb-0 bg-success"
          :label="$t('category_show_text')"
          v-model="showText"
          :onStart="true"
        />
        <div class="row mb-0 mt-3" v-for="(loc, id) in localizations" :key="id">
          <mega-input class="col-1" />
          <mega-input
            class="col-10 ml-auto"
            :placeholder="$t('category_name')"
          />
        </div>
        <!-- <i
          class="i-plus mx-auto cursor-pointer btn"
          @click="localizations.push('')"
        >
        </i> -->

        <div
          class="navbar sm-down:flex-column-reverse md-up:justify-content-end"
        >
          <mega-button
            class="bg-white sm-down:w-100 md-up:mr-2"
            @click="modal = false"
            >{{ $t("close") }}</mega-button
          >
          <mega-button
            :disabled="isDisabled"
            class="btn-primary sm-down:w-100 sm-down:mb-3"
            @click="submit"
            >{{ $t("new_category") }}</mega-button
          >
        </div>
      </div>
    </div>
  </mega-modal>
</template>

<script>
export default {
  props: ["round", "enabled", "likes"],
  data() {
    return {
      modal: false,
      disabledPreview: "",
      activePreview: "",
      localizations: [],
      label_en: "",
      label_ru: "",
      activeFile: null,
      disabledFile: null,
      showIcon: true,
      showText: true
    };
  },
  computed: {
    isDisabled() {
      return !(this.label_ru && this.label_en && this.activeFile);
    }
  },
  methods: {
    renderDisabled(file) {
      if (!file) {
        this.$alert.danger(this.$t("err_ref_file"));
        return;
      }

      this.validateImageSize(file)
        .then(() => {
          this.disabledFile = file;

          let reader = new FileReader();
          reader.readAsDataURL(this.disabledFile);
          reader.onloadend = () => {
            this.disabledPreview = reader.result;
          };
        })
        .catch(err => {
          err == "less"
            ? this.$alert.danger(this.$t("err_ref_size"))
            : this.$alert.danger(this.$t("err_ref_size_more"));
          return;
        });
    },

    renderActive(file) {
      if (!file) {
        this.$alert.danger(this.$t("err_ref_file"));
        return;
      }

      this.validateImageSize(file)
        .then(() => {
          this.activeFile = file;

          let reader = new FileReader();
          reader.readAsDataURL(this.activeFile);
          reader.onloadend = () => {
            this.activePreview = reader.result;
          };
        })
        .catch(err => {
          err == "less"
            ? this.$alert.danger(this.$t("err_ref_size"))
            : this.$alert.danger(this.$t("err_ref_size_more"));
          return;
        });
    },

    open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
    },

    validateImageSize(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.onload = () => {
            switch (this.$route.params.category_type) {
              case "streams":
                if (img.width < 100 && img.height < 100) {
                  reject("less");
                } else if (img.width > 500 && img.height > 500) {
                  reject("more");
                }

                resolve();
                break;
              case "channels":
                if (img.width < 10 && img.height < 10) {
                  reject("less");
                } else if (img.width > 200 && img.height > 200) {
                  reject("more");
                }

                resolve();
                break;
              case "videos":
                if (img.width < 100 && img.height < 100) {
                  reject("less");
                } else if (img.width > 500 && img.height > 500) {
                  reject("more");
                }

                resolve();
                break;
            }
          };

          img.src = reader.result;
        };

        reader.readAsDataURL(file);
      });
    },

    submit() {
      let formData = new FormData();
      switch (this.$route.params.category_type) {
        case "streams":
          formData.append("icon", this.activeFile);
          formData.append("hide_icon", !this.showIcon);
          formData.append("hide_label", !this.showText);
          formData.append("label_ru", this.label_ru);
          formData.append("label_en", this.label_en);

          this.$api.v2base
            .post("/streams/admin/categories", formData, {
              headers: { "Content-Type": "multipart/form-data" }
            })
            .then(res => {
              this.$emit("category-created", res.data);
              this.close();
              this.$alert.success(this.$t("round_likes_success"));
            })
            .catch(() => {
              this.$alert.danger(this.$t("err_server"));
            });
          break;
        case "channels":
          formData.append("file", this.activeFile);

          this.$api.msg
            .post("/upload", formData, {
              headers: { "Content-Type": "multipart/form-data" }
            })
            .then(res => {
              const req_obj = {
                label_ru: this.label_ru,
                label_en: this.label_en,
                hide_label: !this.showText,
                hide_icon: !this.showIcon,
                icon: res.data.id
              };
              formData.delete("file");
              this.$api.msg
                .post("/admin/category/category", req_obj, {
                  headers: { "Content-Type": "application/json" }
                })
                .then(res => {
                  this.$emit("category-created", res.data);
                  this.close();
                  this.$alert.success(this.$t("round_likes_success"));
                })
                .catch(() => {
                  this.$alert.danger(this.$t("err_server"));
                });
            });
          break;
        case "videos":
          break;
      }
    }
  }
};
</script>
